import MenuClosed from "../../assets/icons/menuClosed.svg";
import MenuOpened from "../../assets/icons/menuOpened.svg";

export default function Toggle({ isOpen, setIsOpen }) {
  return (
    <button
      className="toggle-button flex bgGreen50 transition"
      onClick={() => setIsOpen(!isOpen)}
    >
      <img
        className="toggle-icon"
        src={isOpen ? MenuOpened : MenuClosed}
        alt="Hamburger Menu"
      />
    </button>
  );
}
