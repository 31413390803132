import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Design from "../../assets/icons/design.png";
import Engineering from "../../assets/icons/engineering.png";
import ProjectManagement from "../../assets/icons/projectManagement.png";

export default function Card({ title, description }) {
  return (
    <motion.div
      className={`main-services-card flex ${
        title === "Engineering" && "main-services-card-border"
      }`}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ amount: "50%" }}
    >
      <div>
        <motion.img
          src={
            title === "Design"
              ? Design
              : title === "Engineering"
              ? Engineering
              : ProjectManagement
          }
          alt={`${title} icon`}
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        />
        <motion.h3
          className="semiBold white fs600"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {title}
        </motion.h3>
        <motion.p
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          {description}
        </motion.p>
      </div>
      <Link className="transition card-button medium bgGrey15" to="/services">
        Learn More
      </Link>
    </motion.div>
  );
}
