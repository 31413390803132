import JobCard from "./JobCard";

export default function JobGrid({ data }) {
  return (
    <div className="jobs-grid container">
      {data.map((d) => {
        return (
          <JobCard title={d.title} description={d.description} icon={d.icon} />
        );
      })}
    </div>
  );
}
