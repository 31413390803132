import React from "react";
import { motion } from "framer-motion";

import GreyButton from "./GreyButton";
import Button from "./Button";

export default function Hero() {
  return (
    <div className="hero container flex">
      <motion.h1
        className="fs900 semiBold"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        A Digital Product Studio that will Work
      </motion.h1>

      <motion.div
        className="hero-card"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <p className="fs400 grey60">
          For <span className="white bgGrey15">Startups</span>,{" "}
          <span className="white bgGrey15">Enterprise Leaders</span>,{" "}
          <span className="white bgGrey15">Media & Publishers</span> and{" "}
          <span className="white bgGrey15">Social Good</span>
        </p>
      </motion.div>

      <motion.div
        className="hero-buttons flex"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <GreyButton to="/work">Our Works</GreyButton>
        <Button to="/contact">Contact Us</Button>
      </motion.div>
    </div>
  );
}
