import React from "react";

import HeaderInfo from "../components/HeaderInfo";
import PagesInfo from "../components/PagesInfo";
import CardGrid from "../components/services/CardGrid";

import {
  DesignData,
  Engineering,
  ProjectManagement,
} from "../components/services/Data";

export default function Services() {
  return (
    <>
      <HeaderInfo
        title="Our Services"
        description="Transform your brand with our innovative digital solutions that captivate and engage your audience."
        className="header-info-services"
      />
      <PagesInfo
        title="Design"
        description="At Brandivate, our design team is passionate about creating stunning, user-centric designs that captivate your audience and elevate your brand. We believe that great design is not just about aesthetics; it's about creating seamless and intuitive user experiences."
        button="Our design services include:"
      />
      {DesignData.map((data) => {
        return (
          <CardGrid
            title={data.title}
            textOne={data.textOne}
            srcOne={data.srcOne}
            textTwo={data.textTwo}
            srcTwo={data.srcTwo}
            textThree={data.textThree}
            srcThree={data.srcThree}
            textFour={data.textFour}
            srcFour={data.srcFour}
          />
        );
      })}
      <PagesInfo
        title="Engineering"
        description="Our engineering team combines technical expertise with a passion for innovation to build robust and scalable digital solutions. We leverage the latest technologies and best practices to deliver high-performance applications tailored to your specific needs."
        button="Our engineering services include:"
      />
      {Engineering.map((data) => {
        return (
          <CardGrid
            title={data.title}
            textOne={data.textOne}
            srcOne={data.srcOne}
            textTwo={data.textTwo}
            srcTwo={data.srcTwo}
            textThree={data.textThree}
            srcThree={data.srcThree}
            textFour={data.textFour}
            srcFour={data.srcFour}
          />
        );
      })}
      <PagesInfo
        title="Project Management"
        description="Our experienced project management team ensures that your projects are delivered on time, within budget, and according to your specifications. We follow industry-standard methodologies and employ effective communication and collaboration tools to keep you informed throughout the development process."
        button="Our project management services include:"
      />
      {ProjectManagement.map((data) => {
        return (
          <CardGrid
            title={data.title}
            textOne={data.textOne}
            srcOne={data.srcOne}
            textTwo={data.textTwo}
            srcTwo={data.srcTwo}
            textThree={data.textThree}
            srcThree={data.srcThree}
            textFour={data.textFour}
            srcFour={data.srcFour}
          />
        );
      })}
    </>
  );
}
