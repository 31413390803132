import React from "react";

import Card from "./faqs/Card";

const faqs = [
  {
    question: "What services does Brandivate provide?",
    answer:
      "Brandivate offers a range of services including design, engineering, and project management. We specialize in user experience design, web development, mobile app development, custom software development, branding and identity, and more.",
    border: "right",
  },
  {
    question: "How can Brandivate help my business?",
    answer:
      "Brandivate helps businesses grow by providing tailored solutions in design, development, and branding. Whether you need a new website, mobile app, or a refreshed brand identity, Brandivate offers expertise to create impactful, user-friendly, and scalable products that align with your business goals.",
    border: "none",
  },
  {
    question: "What industries does Brandivate work with?",
    answer:
      "Brandivate works with a wide range of industries, including technology, healthcare, retail, education, finance, hospitality, and more. Their adaptable approach allows them to address the unique needs of various sectors.",
    border: "right",
  },
  {
    question: "How long does it take to complete a project with Brandivate?",
    answer:
      "Project timelines vary depending on the scope and complexity of the work. On average, smaller projects may take a few weeks, while larger or custom solutions can take several months. Brandivate ensures transparency and keeps you updated throughout the process.",
    border: "none",
  },
  {
    question:
      "Do you offer ongoing support and maintenance after the project is completed?",
    answer:
      "Yes, Brandivate provides ongoing support and maintenance services. This includes updates, troubleshooting, and enhancements to ensure your website or app stays optimized and functional over time.",
    border: "right",
  },
  {
    question: "Can you work with existing design or development frameworks?",
    answer:
      "Absolutely! Brandivate can work with your existing design systems or development frameworks, integrating new features or making improvements without disrupting your current infrastructure.",

    border: "none",
  },
  {
    question: "How involved will I be in the project development process?",
    answer:
      "Brandivate encourages active collaboration and values client input. You’ll be involved in key stages like requirement gathering, design reviews, and testing to ensure the final product meets your expectations.",
    border: "right",
  },
  {
    question: "Can you help with website or app maintenance and updates?",
    answer:
      "Yes, Brandivate offers services for ongoing maintenance and updates to ensure your website or app remains up-to-date, secure, and aligned with your evolving business needs.",
    border: "none",
  },
];

export default function Faqs() {
  return (
    <div className="faqs container">
      {faqs.map((f, i) => (
        <Card
          key={i}
          index={i + 1}
          question={f.question}
          answer={f.answer}
          border={f.border}
        />
      ))}
    </div>
  );
}
