import React from "react";
import { motion } from "framer-motion";

export default function ShortInput({ name, label, type }) {
  return (
    <motion.div
      className="shortInput flex"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <label className="fs400 white medium" htmlFor={name}>
        {label}
      </label>
      <motion.input
        className="grey40"
        placeholder="Type here"
        type={type}
        name={name}
        id={name}
        initial={{ opacity: 0, x: -30 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true }}
      />
    </motion.div>
  );
}
