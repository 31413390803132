import React from "react";

import Email from "../../assets/icons/email.png";
import Phone from "../../assets/icons/phone.png";
import Location from "../../assets/icons/location.png";

export default function Info({ type, children }) {
  return (
    <div className="info flex">
      <img
        src={type === "Email" ? Email : type === "Phone" ? Phone : Location}
        alt={`Brandivate ${type}`}
      />
      <p>{children}</p>
    </div>
  );
}
