import React from "react";

import HeaderInfo from "../components/HeaderInfo";
import ContactForm from "../components/ContactForm";
import Faqs from "../components/Faqs";
import AboutFooter from "../components/about/AboutFooter";
import ContactInfo from "../components/contactForm/ContactInfo";
import ContactFooter from "../components/contactForm/ContactFooter";

export default function Contact() {
  return (
    <>
      <HeaderInfo
        title="Contact Us"
        description="Get in touch with us today and let us help you with any questions or inquiries you may have."
        className="header-info-contact"
      />
      <ContactInfo />
      <ContactForm />
      <ContactFooter />
      <HeaderInfo
        title="Frequently Asked Questions"
        description="Still you have any questions? Contact our Team via info@brandivate.agency"
        className="header-info-faqs"
      />
      <Faqs />
      {/* <AboutFooter /> */}
    </>
  );
}
