import React from "react";
import HeaderInfo from "../components/HeaderInfo";
import PagesInfo from "../components/PagesInfo";
import Grid from "../components/process/Grid";
import ContactForm from "../components/ContactForm";

export default function Process() {
  return (
    <>
      <HeaderInfo
        title="Process of Starting the Project"
        description="At Brandivate, we value transparency, collaboration, and delivering exceptional results."
        className="header-info-process"
      />
      <PagesInfo
        title="At Brandivate"
        description="We follow a structured and collaborative process to ensure the successful delivery of exceptional digital products. Our process combines industry best practices, creative thinking, and a client-centric approach."
        button="Here's an overview of our typical process:"
      />
      <Grid />
      <ContactForm />
    </>
  );
}
