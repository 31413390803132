import React from "react";
import { motion } from "framer-motion";

import Info from "./Info";

export default function Infos() {
  return (
    <motion.div
      className="infos flex"
      initial="hidden"
      whileInView="show"
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            staggerChildren: 0.1,
            duration: 0.6,
            ease: "easeOut",
          },
        },
      }}
      viewport={{ once: true }}
    >
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          show: { opacity: 1 },
        }}
      >
        <Info type="Email">info@brandivate.agency</Info>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          show: { opacity: 1 },
        }}
      >
        <Info type="Phone">+383 49 251 525</Info>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          show: { opacity: 1 },
        }}
      >
        <Info type="Location">Prishtina, Kosovo</Info>
      </motion.div>
    </motion.div>
  );
}
