import React from "react";

import HeaderInfo from "../components/HeaderInfo";
import AboutComponent from "../components/About";
import AboutGrid from "../components/about/AboutGrid";
import AboutFooter from "../components/about/AboutFooter";

export default function About() {
  return (
    <>
      <HeaderInfo
        title="About Us"
        description="Welcome to Brandivate, where collaboration, expertise, and client-centricity intersect to shape the future of digital innovation."
        className="header-info-about"
      />
      <AboutComponent />
      <AboutGrid />
      {/* <AboutFooter /> */}
    </>
  );
}
