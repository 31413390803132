import Image from "../../assets/images/work.png";

export const work = [
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
  {
    type: "Custom Software for Workflow Automation",
    preview: Image,
    name: "ProTech Solutions",
    link: "https://www.ardbraha.com/",
    description:
      "HungryBites approached us to create a mobile app that streamlined their food delivery service. The app included features like real-time order tracking, easy menu customization, and secure payment options, resulting in improved customer convenience and operational efficiency.",
  },
];
