import { motion } from "framer-motion";

export default function Card({ children, src }) {
  return (
    <motion.div
      className="services-card flex"
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ amount: 0.5 }}
    >
      <motion.img
        src={src}
        alt="Service"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ amount: 0.5 }}
      />
      <motion.h3
        className="medium fs400 grey90"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        viewport={{ amount: 0.5 }}
      >
        {children}
      </motion.h3>
    </motion.div>
  );
}
