import React from "react";
import { Link } from "react-router-dom";

export default function GreyButton({ children, to }) {
  return (
    <Link className="button-gray transition bgGrey20 medium" to={to}>
      {children}
    </Link>
  );
}
