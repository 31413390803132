import React from "react";
import { motion } from "framer-motion";

export default function Card({ count, title, description }) {
  return (
    <motion.div
      className="process-card flex"
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeOut",
          },
        },
      }}
    >
      <motion.div className="flex">
        <motion.h1
          className="green80 fs1000 semiBold"
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            show: { opacity: 1, scale: 1 },
          }}
          transition={{
            duration: 0.4,
            ease: "easeOut",
          }}
        >
          {count}
        </motion.h1>
        <motion.h3
          className="fs600 medium grey60"
          variants={{
            hidden: { opacity: 0, x: -10 },
            show: { opacity: 1, x: 0 },
          }}
          transition={{
            duration: 0.5,
            ease: "easeOut",
          }}
        >
          {title}
        </motion.h3>
      </motion.div>
      <motion.p
        className="grey60"
        variants={{
          hidden: { opacity: 0 },
          show: { opacity: 1 },
        }}
        transition={{
          duration: 0.5,
          delay: 0.3,
        }}
      >
        {description}
      </motion.p>
    </motion.div>
  );
}
