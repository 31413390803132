import { Link } from "react-router-dom";

export default function JobCard({ title, description, icon }) {
  return (
    <div className="job-card flex">
      <div>
        <img src={icon} alt="Job Icon" />
        <h3 className="white medium fs500">{title}</h3>
        <p>{description}</p>
      </div>
      <Link
        className="apply-link white bgGrey15 transition pointer"
        to="/contact"
      >
        Apply now
      </Link>
    </div>
  );
}
