import React from "react";
import { motion } from "framer-motion";

import Spotify from "../assets/companies/spotify.png";

export default function Companies() {
  const images = [...Array(6)].map((_, index) => ({
    id: index,
    src: Spotify,
    alt: "Spotify Logo",
  }));

  return (
    <motion.div
      className="companies container"
      initial="hidden"
      whileInView="show"
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeOut",
            staggerChildren: 0.2,
          },
        },
      }}
      viewport={{ once: true }}
    >
      <motion.div
        className="companies-num medium"
        variants={{
          hidden: { opacity: 0, y: 20 },
          show: {
            opacity: 1,
            y: 0,
            x: "-50%",
          },
        }}
        style={{
          transform: "translateX(-50%)",
        }}
      >
        Trusted By 10+ Companies
      </motion.div>
      <motion.div
        className="carousel-container"
        style={{ overflow: "hidden", width: "100%" }}
      >
        <motion.div
          className="carousel-track"
          style={{
            display: "flex",
            gap: "1rem",
            willChange: "transform",
          }}
          animate={{ x: ["0%", "-100%"] }}
          transition={{
            duration: 10,
            ease: "linear",
            repeat: Infinity,
          }}
        >
          {[...images, ...images].map((image, index) => (
            <motion.div
              key={index}
              className="carousel-item"
              style={{
                minWidth: "15%",
                flexShrink: 0,
              }}
            >
              <motion.img
                src={image.src}
                alt={image.alt}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
