import React from "react";

import { About } from "../process/Data";
import Card from "../process/Card";

export default function AboutGrid() {
  return (
    <div className="container">
      <div className="process-container">
        {About.map((process) => {
          return (
            <Card
              count={process.order}
              title={process.title}
              description={process.description}
            />
          );
        })}
      </div>
    </div>
  );
}
