import Email from "../../assets/icons/email.png";
import Phone from "../../assets/icons/phone.png";
import Location from "../../assets/icons/location.png";

export default function ContactInfo() {
  return (
    <div className="container flex contact-info">
      <div className="flex bgGrey15">
        <img src={Email} alt="Email Icon" />
        <p className="white medium">info@brandivate.agency</p>
      </div>
      <div className="flex bgGrey15">
        <img src={Phone} alt="Phone Icon" />
        <p className="white medium">+383 49 251 525</p>
      </div>
      <div className="flex bgGrey15">
        <img src={Location} alt="Location Icon" />
        <p className="white medium">Prishtina, Kosovo</p>
      </div>
    </div>
  );
}
