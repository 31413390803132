import React from "react";
import HeaderInfo from "../components/HeaderInfo";
import PagesInfo from "../components/PagesInfo";
import Grid from "../components/work/Grid";

export default function Work() {
  return (
    <>
      <HeaderInfo
        title="Our Works"
        description="Discover a portfolio of visually stunning and strategically crafted digital projects that showcase our creativity and expertise."
        className="header-info-work"
      />
      <PagesInfo
        title="At Brandivate"
        description="We have had the privilege of working with a diverse range of clients and delivering exceptional digital products that drive success."
        button="Here are ten examples of our notable works:"
      />
      <Grid />
    </>
  );
}
