import React from "react";

import HeaderInfo from "../components/HeaderInfo";
import PagesInfo from "../components/PagesInfo";
import AboutFooter from "../components/about/AboutFooter";
import Grid from "../components/careers/Grid";
import JobGrid from "../components/careers/JobGrid";
import Subtitle from "../components/careers/Subtitle";

import {
  designJobs,
  developmentJobs,
  managementJobs,
  qaJobs,
} from "../components/careers/Data";

export default function Careers() {
  return (
    <>
      <HeaderInfo
        title="Join Our Team at Brandivate"
        description="Unlock your potential and join our team of innovators and problem solvers."
        className="header-info-contact"
      />
      <PagesInfo
        title="Welcome to Brandivate, where talent meets opportunity!"
        description="At Brandivate, we believe that the success of our agency lies in the talent, passion, and dedication of our team members. We are a digital product agency that thrives on innovation, creativity, and collaboration. If you're ready to make a difference and contribute to cutting-edge projects, we invite you to explore career opportunities with us."
        button="Why Work at Brandivate?"
      />
      <Grid />
      <PagesInfo
        title="Current Openings"
        description="We are always on the lookout for talented individuals who are passionate about creating exceptional digital experiences. Whether you're a designer, engineer, project manager, or have skills that align with our agency's mission, we encourage you to explore our open positions."
      />
      <Subtitle>Design Job Openings</Subtitle>
      <JobGrid data={designJobs} />
      <Subtitle>Development Job Openings</Subtitle>
      <JobGrid data={developmentJobs} />
      <Subtitle>Management Job Openings</Subtitle>
      <JobGrid data={managementJobs} />
      <Subtitle>QA Job Openings</Subtitle>
      <JobGrid data={qaJobs} />
      {/* <AboutFooter /> */}
    </>
  );
}
