import React from "react";
import { motion } from "framer-motion";

import ShortInput from "./contactForm/ShortInput";
import Checkboxes from "./contactForm/Checkboxes";
import SliderInput from "./contactForm/SliderInput";
import Textarea from "./contactForm/Textarea";

export default function ContactForm() {
  return (
    <div className="container">
      <form className="contact-form flex">
        <div className="shortInput-container flex">
          <ShortInput name="name" label="Full Name" type="text" />
          <ShortInput name="email" label="Email" type="email" />
        </div>
        <Checkboxes />
        <SliderInput />
        <Textarea />
        <motion.button
          className="button-green transition grey15 bgGreen50 medium"
          type="submit"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          Submit
        </motion.button>
      </form>
    </div>
  );
}
