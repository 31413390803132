import React from "react";
import { motion } from "framer-motion";

import Infos from "./Infos";

export default function Copyright() {
  return (
    <motion.div
      className="copyright container flex"
      initial="hidden"
      whileInView="show"
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeOut",
          },
        },
      }}
      viewport={{ once: true }}
    >
      <Infos />
      <motion.p
        initial="hidden"
        whileInView="show"
        variants={{
          hidden: { opacity: 0, y: 20 },
          show: {
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.6,
              ease: "easeOut",
            },
          },
        }}
        viewport={{ once: true }}
      >
        &copy; {new Date().getFullYear()} Brandivate. All rights reserved.
      </motion.p>
    </motion.div>
  );
}
