import React from "react";
import { motion } from "framer-motion";

export default function Checkboxes() {
  return (
    <motion.div
      className="checkboxes flex"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <label className="fs400 white medium" htmlFor="reason">
        Why are you contacting us?
      </label>
      <motion.div
        className="checkboxes-inputs flex"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true }}
      >
        <motion.div
          className="flex"
          initial={{ opacity: 0, x: -30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }}
          viewport={{ once: true }}
        >
          <input type="checkbox" id="web-design" />
          <div className="checkbox bgGrey15"></div>
          <label className="pointer" htmlFor="web-design">
            Web Design
          </label>
        </motion.div>
        <motion.div
          className="flex"
          initial={{ opacity: 0, x: -30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
          viewport={{ once: true }}
        >
          <input type="checkbox" id="collaboration" />
          <div className="checkbox bgGrey15"></div>
          <label className="pointer" htmlFor="collaboration">
            Collaboration
          </label>
        </motion.div>
        <motion.div
          className="flex"
          initial={{ opacity: 0, x: -30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.8 }}
          viewport={{ once: true }}
        >
          <input type="checkbox" id="mobile" />
          <div className="checkbox bgGrey15"></div>
          <label className="pointer" htmlFor="mobile">
            Mobile App Design
          </label>
        </motion.div>
        <motion.div
          className="flex"
          initial={{ opacity: 0, x: -30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 1 }}
          viewport={{ once: true }}
        >
          <input type="checkbox" id="other" />
          <div className="checkbox bgGrey15"></div>
          <label className="pointer" htmlFor="other">
            Others
          </label>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
