import React from "react";
import { Link } from "react-router-dom";

import BrandivateLogo from "../assets/brand/logo.png";

export default function Logo() {
  return (
    <Link to="/" className="logo flex">
      <img src={BrandivateLogo} alt="Brandivate Logo" />
      <h3 className="white fs500 bold">Brandivate</h3>
    </Link>
  );
}
