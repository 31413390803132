import React from "react";

import Main from "./footer/Main";
import Copyright from "./footer/Copyright";

export default function Footer() {
  return (
    <footer className="footer">
      <Main />
      <Copyright />
    </footer>
  );
}
