import React from "react";

import { Processes } from "./Data";
import Card from "./Card";

export default function Grid() {
  return (
    <div className="container">
      <div className="process-container">
        {Processes.map((process) => {
          return (
            <Card
              count={process.order}
              title={process.title}
              description={process.description}
            />
          );
        })}
      </div>
    </div>
  );
}
