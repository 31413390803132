import React, { useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function Card({ index, question, answer, border }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className={`faqs-card pointer flex border-${border}`}
      onClick={() => setIsOpen(!isOpen)}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ amount: "50%" }}
    >
      <div
        className={`question flex transition pointer ${
          isOpen && "question-open clrGreen70"
        }`}
      >
        <div className="question-question flex">
          <div className="question-num flex">
            <motion.h2
              className={`fs600 semiBold white transition ${
                isOpen && "green50"
              }`}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              0{index}
            </motion.h2>
          </div>
          <motion.h3
            className={`question-title transition medium ${
              isOpen && "green70"
            }`}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            {question}
          </motion.h3>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <FontAwesomeIcon
            icon={isOpen ? faX : faPlus}
            color={isOpen ? "#c5ff66" : "#fff"}
          />
        </motion.div>
      </div>
      <div className={`answer transition ${!isOpen && "hidden"}`}>
        <p className="transition">{answer}</p>
      </div>
    </motion.div>
  );
}
