import React from "react";
import { motion } from "framer-motion";

export default function PagesInfo({ title, description, button }) {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ amount: 0.5 }}
    >
      <div className="pagesInfo">
        <motion.h2
          className="semiBold fs800"
          initial={{ x: -20, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ amount: 0.5 }}
        >
          {title}
        </motion.h2>
        <motion.p
          initial={{ x: 20, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ amount: 0.5 }}
        >
          {description}
        </motion.p>
        {button && (
          <motion.button
            className="fs400 bgGrey15 white"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            viewport={{ amount: 0.5 }}
          >
            {button}
          </motion.button>
        )}
      </div>
    </motion.div>
  );
}
