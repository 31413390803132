import Card from "./Card";
import { requirements } from "./Data";

export default function Grid() {
  return (
    <div className="career-grid container">
      {requirements.map((r, i) => {
        return <Card title={r.title} description={r.description} key={i} />;
      })}
    </div>
  );
}
