import React from "react";
import { motion } from "framer-motion";

export default function Textarea() {
  return (
    <motion.div
      className="textarea flex"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <motion.label
        className="fs400 white medium"
        htmlFor="message"
        initial={{ opacity: 0, x: -30 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        viewport={{ once: true }}
      >
        Your Message
      </motion.label>
      <motion.textarea
        className="grey40"
        rows={3}
        id="message"
        name="message"
        placeholder="Type here"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true }}
      ></motion.textarea>
    </motion.div>
  );
}
