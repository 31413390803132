import React from "react";
import { Link } from "react-router-dom";

export default function Button({ children, to }) {
  return (
    <Link className="button-green transition grey15 bgGreen50 medium" to={to}>
      {children}
    </Link>
  );
}
