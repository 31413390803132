import React from "react";
import { motion } from "framer-motion";

import Expertise from "../../assets/icons/expertise.png";
import Client from "../../assets/icons/client.png";
import Result from "../../assets/icons/result.png";
import Collaborative from "../../assets/icons/collaborative.png";

export default function Card({ title, description, border }) {
  return (
    <motion.div
      className={`characteristics-card flex card-border-${border}`}
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ amount: "50%" }}
    >
      <div className="flex">
        <motion.img
          src={
            title === "Expertise"
              ? Expertise
              : title === "Client-Centric Approach"
              ? Client
              : title === "Results-Driven Solutions"
              ? Result
              : Collaborative
          }
          alt={`${title} Icon`}
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        />
        <motion.h3
          className="fs500 medium white"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {title}
        </motion.h3>
      </div>
      <motion.p
        initial={{ opacity: 0, y: 10 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        {description}
      </motion.p>
    </motion.div>
  );
}
