import React, { useState } from "react";
import { motion } from "framer-motion";

const RangeSlider = () => {
  const [minValue, setMinValue] = useState(2000);
  const [maxValue, setMaxValue] = useState(10000);

  const handleMinChange = (event) => {
    const value = Math.min(Number(event.target.value), maxValue - 100);
    setMinValue(value);
  };

  const handleMaxChange = (event) => {
    const value = Math.max(Number(event.target.value), minValue + 100);
    setMaxValue(value);
  };

  const minPercent = ((minValue - 100) / 49900) * 100;
  const maxPercent = ((maxValue - 100) / 49900) * 100;

  return (
    <motion.div
      className="sliderInput flex"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <motion.label
        className="fs400 white medium"
        htmlFor="message"
        initial={{ opacity: 0, x: -30 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        viewport={{ once: true }}
      >
        Your Budget
      </motion.label>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true }}
      >
        Slide to indicate your budget range
      </motion.p>
      <motion.div
        className="range-slider"
        initial={{ opacity: 0, scale: 0.95 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }}
        viewport={{ once: true }}
      >
        <div className="track"></div>
        <div
          className="highlight"
          style={{
            left: `${minPercent}%`,
            right: `${100 - maxPercent}%`,
          }}
        ></div>
        <motion.input
          type="range"
          min="100"
          max="50000"
          step="100"
          value={minValue}
          onChange={handleMinChange}
          initial={{ opacity: 0, x: -30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
          viewport={{ once: true }}
        />
        <motion.input
          type="range"
          min="100"
          max="50000"
          step="100"
          value={maxValue}
          onChange={handleMaxChange}
          initial={{ opacity: 0, x: 30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }}
          viewport={{ once: true }}
        />
        <div
          className="value-label"
          style={{ left: `${minPercent}%` }}
        >{`${minValue}€`}</div>
        <div
          className="value-label"
          style={{ left: `${maxPercent}%` }}
        >{`${maxValue}€`}</div>
      </motion.div>
    </motion.div>
  );
};

export default RangeSlider;
