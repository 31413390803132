import React from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

import Link from "./Link";
// import Button from "../Button";

export default function Nav({ isOpen }) {
  const path = useLocation().pathname;

  const linkVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.ul
      className={`nav flex transition ${isOpen && "nav-open"}`}
      initial="hidden"
      animate="visible"
      transition={{ staggerChildren: 0.2 }}
    >
      <motion.li variants={linkVariants}>
        <Link active={path === "/"} to="/">
          Home
        </Link>
      </motion.li>
      <motion.li variants={linkVariants}>
        <Link active={path === "/services"} to="/services">
          Services
        </Link>
      </motion.li>
      <motion.li variants={linkVariants}>
        <Link active={path === "/work"} to="/work">
          Work
        </Link>
      </motion.li>
      <motion.li variants={linkVariants}>
        <Link active={path === "/process"} to="/process">
          Process
        </Link>
      </motion.li>
      <motion.li variants={linkVariants}>
        <Link active={path === "/about"} to="/about">
          About
        </Link>
      </motion.li>
      <motion.li variants={linkVariants}>
        <Link active={path === "/careers"} to="/careers">
          Careers
        </Link>
      </motion.li>
      {isOpen && (
        <motion.li variants={linkVariants}>
          <Link active={path === "/contact"} to="/contact">
            Contact
          </Link>
        </motion.li>
      )}
    </motion.ul>
  );
}
