import React from "react";

import Card from "./characteristics/Card";

const characteristics = [
  {
    title: "Expertise",
    description:
      "Our team consists of highly skilled professionals who have a deep understanding of the digital landscape. We stay updated with the latest industry trends and best practices to deliver cutting-edge solutions.",
    border: "right",
  },
  {
    title: "Client-Centric Approach",
    description:
      "We prioritize our clients and their unique needs. We listen to your ideas, challenges, and goals, and tailor our services to meet your specific requirements. Your success is our success.",
    border: "bottom",
  },
  {
    title: "Results-Driven Solutions",
    description:
      "Our primary focus is on delivering results. We combine creativity and technical expertise to create digital products that drive business growth, enhance user experiences, and provide a competitive advantage.",
    border: "top",
  },
  {
    title: "Collaborative Partnership",
    description:
      "We value long-term relationships with our clients. We see ourselves as your digital partner, providing ongoing support, maintenance, and updates to ensure your digital products continue to thrive.",
    border: "left",
  },
];

export default function Characteristics() {
  return (
    <div className="characteristics flex container">
      {characteristics.map((c, i) => (
        <Card
          key={i}
          title={c.title}
          description={c.description}
          border={c.border}
        />
      ))}
    </div>
  );
}
