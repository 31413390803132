import React from "react";

import Hero from "../components/Hero";
import Companies from "../components/Companies";
import HeaderInfo from "../components/HeaderInfo";
import MainServices from "../components/MainServices";
import Characteristics from "../components/Characteristics";
import Testimonials from "../components/Testimonials";
import Faqs from "../components/Faqs";
import ContactForm from "../components/ContactForm";

export default function Home() {
  return (
    <>
      <Hero />
      <Companies />
      <HeaderInfo
        title="Our Services"
        description="Transform your brand with our innovative digital solutions that captivate and engage your audience."
        className="header-info-services"
      />
      <MainServices />
      <HeaderInfo
        title="Why Choose Brandivate?"
        description="Experience excellence in digital craftsmanship with our team of skilled professionals dedicated to delivering exceptional results."
        className="header-info-whychoose"
      />
      <Characteristics />
      <HeaderInfo
        title="What our Clients say About us"
        description="At Brandivate, we take pride in delivering exceptional digital products and services that drive success for our clients. Here's what some of our satisfied clients have to say about their experience working with us"
        className="header-info-about"
      />
      <Testimonials />
      <HeaderInfo
        title="Frequently Asked Questions"
        description="You still have any questions? Contact our Team via info@brandivate.agency"
        className="header-info-faqs"
      />
      <Faqs />
      <HeaderInfo
        title="Contact Us"
        description="Get in touch with us today and let us help you with any questions or inquiries you may have."
        className="header-info-faqs"
      />
      <ContactForm />
    </>
  );
}
