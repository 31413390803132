import { motion } from "framer-motion";
import Card from "./Card";
import Heading from "./Heading";

export default function UX({
  title,
  srcOne,
  srcTwo,
  srcThree,
  srcFour,
  textOne,
  textTwo,
  textThree,
  textFour,
}) {
  return (
    <div className="services-category">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ amount: 0.5 }}
      >
        <Heading>{title}</Heading>
      </motion.div>
      <div className="services-container container">
        <Card src={srcOne}>{textOne}</Card>
        <Card src={srcTwo}>{textTwo}</Card>
        <Card src={srcThree}>{textThree}</Card>
        <Card src={srcFour}>{textFour}</Card>
      </div>
    </div>
  );
}
