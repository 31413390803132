import React from "react";
import { motion } from "framer-motion";

import Facebook from "../assets/icons/facebook.png";
import Twitter from "../assets/icons/twitter.png";
import LinkedIn from "../assets/icons/linkedin.png";

export default function Socials() {
  return (
    <motion.div
      className="socials flex"
      initial={{ opacity: 0, x: 20 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <p className="medium">Stay Connected</p>
      <div className="flex">
        <img src={Facebook} alt="Facebook Logo" />
        <img src={Twitter} alt="Twitter Logo" />
        <img src={LinkedIn} alt="LinkedIn Logo" />
      </div>
    </motion.div>
  );
}
