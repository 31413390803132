import Serviceone from "../../assets/icons/services/one.png";
import Servicetwo from "../../assets/icons/services/two.png";
import Servicethree from "../../assets/icons/services/three.png";
import Servicefour from "../../assets/icons/services/four.png";
import Servicefive from "../../assets/icons/services/five.png";
import Servicesix from "../../assets/icons/services/six.png";
import Serviceseven from "../../assets/icons/services/seven.png";
import Serviceeight from "../../assets/icons/services/eight.png";
import Servicenine from "../../assets/icons/services/nine.png";
import Serviceten from "../../assets/icons/services/ten.png";
import Serviceeleven from "../../assets/icons/services/eleven.png";
import Servicetwelve from "../../assets/icons/services/twelve.png";
import Servicethirteen from "../../assets/icons/services/thirteen.png";
import Servicefourteen from "../../assets/icons/services/fourteen.png";
import Servicefifteen from "../../assets/icons/services/fifteen.png";
import Servicesixteen from "../../assets/icons/services/sixteen.png";
import Serviceseventeen from "../../assets/icons/services/seventeen.png";
import Serviceeighteen from "../../assets/icons/services/eighteen.png";
import Servicenineteen from "../../assets/icons/services/nineteen.png";
import Servicetwenty from "../../assets/icons/services/twenty.png";
import Servicetwentyone from "../../assets/icons/services/twenty-one.png";
import Servicetwentytwo from "../../assets/icons/services/twenty-two.png";
import Servicetwentythree from "../../assets/icons/services/twenty-three.png";
import Servicetwentyfour from "../../assets/icons/services/twenty-four.png";
import Servicetwentyfive from "../../assets/icons/services/twenty-five.png";
import Servicetwentysix from "../../assets/icons/services/twenty-six.png";
import Servicetwentyseven from "../../assets/icons/services/twenty-seven.png";
import Servicetwentyeight from "../../assets/icons/services/twenty-eight.png";
import Servicetwentynine from "../../assets/icons/services/twenty-nine.png";
import ServiceThirty from "../../assets/icons/services/thirty.png";
import ServiceThirtyone from "../../assets/icons/services/thirty-one.png";
import ServiceThirtytwo from "../../assets/icons/services/thirty-two.png";
import ServiceThirtythree from "../../assets/icons/services/thirty-three.png";
import ServiceThirtyfour from "../../assets/icons/services/thirty-four.png";
import ServiceThirtyfive from "../../assets/icons/services/thirty-five.png";
import ServiceThirtysix from "../../assets/icons/services/thirty-six.png";

export const DesignData = [
  {
    title: "User Experience (UX) Design",
    textOne: "User Research and Persona Development",
    srcOne: Serviceone,
    textTwo: "Information Architecture and Wireframing",
    srcTwo: Servicetwo,
    textThree: "Interactive Prototyping and User Testing",
    srcThree: Servicethree,
    textFour: "UI Design and Visual Branding",
    srcFour: Servicefour,
  },
  {
    title: "User Interface (UI) Design",
    textOne: "Intuitive and Visually Appealing Interface Design",
    srcOne: Servicefive,
    textTwo: "Custom Iconography and Illustration",
    srcTwo: Servicesix,
    textThree: "Typography and Color Palette Selection",
    srcThree: Serviceseven,
    textFour: "Responsive Design for Various Devices",
    srcFour: Serviceeight,
  },
  {
    title: "Branding and Identity",
    textOne: "Logo Design and Visual Identity Development",
    srcOne: Servicenine,
    textTwo: "Brand Strategy and Positioning",
    srcTwo: Serviceten,
    textThree: "Brand Guidelines and Style Guides",
    srcThree: Serviceeleven,
    textFour: "Marketing Collateral Design (Brochures, Business Cards, etc.)",
    srcFour: Servicetwelve,
  },
];

export const Engineering = [
  {
    title: "Web Development",
    textOne: "Front-End Development (HTML, CSS, JavaScript)",
    srcOne: Servicethirteen,
    textTwo: "Back-End Development (PHP, Python, Ruby)",
    srcTwo: Servicefourteen,
    textThree:
      "Content Management System (CMS) Development (WordPress, Drupal)",
    srcThree: Servicefifteen,
    textFour: "E-Commerce Platform Development (Magento, Shopify)",
    srcFour: Servicesixteen,
  },
  {
    title: "Mobile App Development",
    textOne: "Native iOS and Android App Development",
    srcOne: Serviceseventeen,
    textTwo: "Cross-Platform App Development (React Native, Flutter)",
    srcTwo: Serviceeighteen,
    textThree: "App Prototyping and UI/UX Design Integration",
    srcThree: Servicenineteen,
    textFour: "App Testing, Deployment, and Maintenance",
    srcFour: Servicetwenty,
  },
  {
    title: "Custom Software Development",
    textOne: "Enterprise Software Development",
    srcOne: Servicetwentyone,
    textTwo: "Custom Web Application Development",
    srcTwo: Servicetwentytwo,
    textThree: "Integration with Third-Party APIs and Systems",
    srcThree: Servicetwentythree,
    textFour: "Legacy System Modernization and Migration",
    srcFour: Servicetwentyfour,
  },
];

export const ProjectManagement = [
  {
    title: "Project Planning and Scoping",
    textOne: "Requirements Gathering and Analysis",
    srcOne: Servicetwentyfive,
    textTwo: "Project Roadmap and Timeline Development",
    srcTwo: Servicetwentysix,
    textThree: "Resource Allocation and Task Assignment",
    srcThree: Servicetwentyseven,
    textFour: "Risk Assessment and Mitigation Strategies",
    srcFour: Servicetwentyeight,
  },
  {
    title: "Agile Development",
    textOne: "Iterative Development and Sprints",
    srcOne: Servicetwentynine,
    textTwo: "Scrum or Kanban Methodology Implementation",
    srcTwo: ServiceThirty,
    textThree: "Regular Progress Updates and Demos",
    srcThree: ServiceThirtyone,
    textFour: "Continuous Improvement and Feedback Incorporation",
    srcFour: ServiceThirtytwo,
  },
  {
    title: "Quality Assurance and Testing",
    textOne: "Test Planning and Execution",
    srcOne: ServiceThirtythree,
    textTwo: "Functional and Usability Testing",
    srcTwo: ServiceThirtyfour,
    textThree: "Performance and Security Testing",
    srcThree: ServiceThirtyfive,
    textFour: "Bug Tracking and Issue Resolution",
    srcFour: ServiceThirtysix,
  },
];
