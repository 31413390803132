import Image from "../assets/images/aboutImage.png";

export default function About() {
  return (
    <div className="about-container container flex">
      <div className="about-text">
        <h2 className="white fs800 semiBold">About Brandivate</h2>
        <p className="fs400 grey90">
          Brandivate is a digital product agency that is passionate about
          crafting exceptional digital experiences. We specialize in design,
          engineering, and project management, helping businesses thrive in the
          digital landscape. At Brandivate, we follow a structured and
          collaborative process to ensure the successful delivery of exceptional
          digital products. Our process combines industry best practices,
          creative thinking, and a client-centric approach.
        </p>
      </div>
      <img src={Image} alt="About Banner" />
    </div>
  );
}
