import React from "react";
import { motion } from "framer-motion";

import Link from "../footer/Link";

export default function Links() {
  return (
    <motion.ul
      className="footer-nav flex"
      initial="hidden"
      whileInView="show"
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: {
          opacity: 1,
          y: 0,
          transition: {
            staggerChildren: 0.1,
            duration: 0.6,
            ease: "easeOut",
          },
        },
      }}
      viewport={{ once: true }}
    >
      <motion.li variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}>
        <Link to="/">Home</Link>
      </motion.li>
      <motion.li variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}>
        <Link to="/services">Services</Link>
      </motion.li>
      <motion.li variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}>
        <Link to="/work">Work</Link>
      </motion.li>
      <motion.li variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}>
        <Link to="/process">Process</Link>
      </motion.li>
      <motion.li variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}>
        <Link to="/about">About</Link>
      </motion.li>
      <motion.li variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}>
        <Link to="/careers">Careers</Link>
      </motion.li>
      <motion.li variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}>
        <Link to="/contact">Contact</Link>
      </motion.li>
    </motion.ul>
  );
}
