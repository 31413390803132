import Socials from "../Socials";

export default function ContactFooter() {
  return (
    <div className="contact-footer flex container">
      <div className="opening-days flex">
        <h3>Opening Days</h3>
        <h3 className="bgGrey15">Monday to Friday</h3>
      </div>
      <Socials />
    </div>
  );
}
