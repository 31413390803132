import { motion } from "framer-motion";
import ArrowRedirect from "../../assets/icons/arrowRedirect.png";

export default function Card({ type, name, link, src, description }) {
  return (
    <motion.div
      className="work-card"
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.4, ease: "easeOut" }}
      viewport={{ amount: 0.2 }}
    >
      <motion.h3
        className="fs500 grey60 medium"
        initial={{ opacity: 0, y: -10 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        {type}
      </motion.h3>
      <motion.img
        className="preview"
        src={src}
        alt={`${name} Website`}
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      />
      <motion.h3
        className="white medium fs500"
        initial={{ opacity: 0, y: 10 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {name}
      </motion.h3>
      <motion.div
        className="flex"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <a
          className="link-preview bgGrey15 grey60 fs400"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {link}
        </a>
        <a href={link} target="_blank" rel="noreferrer">
          <motion.img
            src={ArrowRedirect}
            alt="Arrow Redirect"
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          />
        </a>
      </motion.div>
      <motion.p
        className="grey60"
        initial={{ opacity: 0, y: 10 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        {description}
      </motion.p>
    </motion.div>
  );
}
