import Card from "./Card";
import { work } from "./Data";

export default function Grid() {
  return (
    <div className="container">
      <div className="work-grid">
        {work.map((project, index) => (
          <Card
            key={index}
            type={project.type}
            name={project.name}
            src={project.preview}
            description={project.description}
            link={project.link}
          />
        ))}
      </div>
    </div>
  );
}
