import React from "react";
import { motion } from "framer-motion";

export default function HeaderInfo({ title, description, className }) {
  return (
    <div className="container">
      <motion.div
        className={`header-info ${className}`}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{
          once: true,
          amount: "50%",
        }}
      >
        <motion.h2
          className="semiBold fs800"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {title}
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
        >
          {description}
        </motion.p>
      </motion.div>
    </div>
  );
}
