import React from "react";

import Card from "./testimonials/Card";

const testimonials = [
  {
    title:
      "Brandivate has been Instrumental in Transforming our Online Presence.",
    description:
      "Their team's expertise in web development and design resulted in a visually stunning and user-friendly e-commerce platform. Our online sales have skyrocketed, and we couldn't be happier.",
    author: "Ard Braha",
    position: "Full Stack Developer",
    website: "https://www.ardbraha.com/",
    border: "right",
  },
  {
    title: "Working with Brandivate was a breeze.",
    description:
      "They understood our vision for a mobile app that streamlined our food delivery service. The app they delivered exceeded our expectations, and our customers love the seamless ordering experience. Brandivate is a trusted partner we highly recommend.",
    author: "Ard Braha",
    position: "Full Stack Developer",
    website: "https://www.ardbraha.com/",
    border: "bottom",
  },
  {
    title:
      "Brandivate developed a comprehensive booking and reservation system for our event management company",
    description:
      "Their attention to detail and commitment to delivering a user-friendly platform was evident throughout the project. The system has streamlined our operations and enhanced our clients' event experiences.",
    author: "Ard Braha",
    position: "Full Stack Developer",
    website: "https://www.ardbraha.com/",
    border: "top",
  },
  {
    title: "ProTech Solutions turned to Brandivate to automate our workflow",
    description:
      "They delivered an exceptional custom software solution. The system has significantly increased our productivity and reduced manual errors. Brandivate's expertise and professionalism have made them a trusted technology partner.",
    author: "Ard Braha",
    position: "Full Stack Developer",
    website: "https://www.ardbraha.com/",
    border: "left",
  },
  {
    title:
      "Brandivate designed and developed a captivating web portal for showcasing our real estate listings.",
    description:
      "The platform is visually appealing and easy to navigate, allowing potential buyers to find their dream homes effortlessly. Brandivate's expertise in the real estate industry is unmatched.",
    author: "Ard Braha",
    position: "Full Stack Developer",
    website: "https://www.ardbraha.com/",
    border: "top",
  },
  {
    title:
      "FitLife Tracker wanted a mobile app that tracked fitness activities and provided personalized workout plans.",
    description:
      "Brandivate's team developed an intuitive and feature-rich app that has helped our users stay motivated and achieve their fitness goals. We highly recommend Brandivate for any health and fitness app development needs.",
    author: "Ard Braha",
    position: "Full Stack Developer",
    website: "https://www.ardbraha.com/",
    border: "top",
  },
];

export default function Testimonials() {
  return (
    <div className="testimonials flex container">
      {testimonials.map((t, i) => (
        <Card
          key={i}
          title={t.title}
          description={t.description}
          author={t.author}
          position={t.position}
          website={t.website}
          border={t.border}
        />
      ))}
    </div>
  );
}
