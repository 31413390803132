import React from "react";
import { motion } from "framer-motion";

import Logo from "../Logo";
import Socials from "../Socials";
import Links from "./Links";

export default function Main() {
  return (
    <motion.div
      className="footer-main container flex"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <Logo />
      <Links />
      <Socials />
    </motion.div>
  );
}
