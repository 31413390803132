import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import { motion } from "framer-motion";

import Nav from "./header/Nav";
import Button from "./Button";
import Logo from "./Logo";
import Toggle from "./header/Toggle";

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get current location

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <motion.header
      className="header container flex"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <Logo />
      <Nav isOpen={isOpen} />
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
        className="header-button-container"
      >
        <Button to="/contact">Contact</Button>
      </motion.div>
      <motion.div
        className="toggle-container pointer"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Toggle isOpen={isOpen} setIsOpen={setIsOpen} />
      </motion.div>
    </motion.header>
  );
}
