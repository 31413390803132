import React from "react";
import { Link } from "react-router-dom";

export default function NavLink({ children, to, active }) {
  return (
    <li>
      <Link
        className={`nav-link grey90 transition medium ${
          active && "active bgGrey15 white"
        }`}
        to={to}
      >
        {children}
      </Link>
    </li>
  );
}
